import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import {
  fetchAssignedProcessesByResource,
  fetchProcessesByOrchestrator,
  fetchResource,
  updateResource,
} from "redux/actions/services/index";
import { RESOURCE_BASE_URL } from "util/index";
import ConfirmMessage from "components/ConfirmMessage";
import ProcessSelector from "../../ProcessSelector";
import CustomButton from "components/CustomButton";
import CircularLoader from "components/Loaders/CircularLoader";
import DialogWithTabs from "components/FormComponents/DialogWithTabs";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import { isPermitted } from "components/HasPermission";
import CustomSwitch from "pages/Services/components/CustomSwitch";

const DEFAULT_ORS_WIDTH = 1920;
const DEFAULT_ORS_HEIGHT = 1080;
const DEFAULT_ORS_DPI = 125;
const DEFAULT_ORS_OPEN_BEFORE = 2;

export default function RobotForm(props) {
  const dispatch = useDispatch();
  const {
    setValue, formState: { dirtyFields, errors }, register, getValues, trigger, watch
  } = useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { idResource, mode = "view" } = useParams();
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [resourceDataLoading, setResourceDataLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const disableFields = !(mode === "add" || mode === "edit");
  const [resource, setResource] = useState({
    resourceDisplayName: "",
  });

    const tabs = [
        {
            id: "ressource-overview-tab",
            label: "fleet.formSection.fleetInfo",
        },
        {
            id: "ressource-process-tab",
            label: "fleet.formSection.processes",
        },
        {
            id: "ressource-ors-tab",
            label: "resources.formSection.orsInfo",
            disabledTab: !watch("isOrsEnabled"),
        },
    ];
    const enabledTabs = tabs.filter((item) => !item.disabledTab);

  useEffect(() => {
    setResourceDataLoading(true);
    dispatch(fetchResource(idResource)).then((res) => {
      dispatch(fetchAssignedProcessesByResource(idResource));
      if (res?.data) {
        setResource(res.data);
        setValue("resourceId", res.data.resourceId);
        setValue("processesRunning", res.data.processesRunning);
        setValue("resourceDisplayName", res.data.resourceDisplayName);
        setValue("resourceName", res.data.resourceName);
        setValue("actionsRunning", res.data.actionsRunning);
        setValue("displayStatus", res.data.displayStatus ? t(res.data.displayStatus) : t("Offline"));
        setValue("isOrsEnabled", res.data.orsDetail !== null);
        setValue("orsLogin", res.data.orsDetail?.username);
        setValue("orsPassword", res.data.orsDetail?.password);
        setValue("orsResourceVmIp", res.data.orsDetail?.host);
        setValue("orsScreenHeight", res.data.orsDetail?.height ?? DEFAULT_ORS_HEIGHT);
        setValue("orsScreenWidth", res.data.orsDetail?.width ?? DEFAULT_ORS_WIDTH);
        setValue("orsScreenDpi", res.data.orsDetail?.dpi ?? DEFAULT_ORS_DPI);
        setValue("orsOpenBefore", res.data.orsDetail?.openBefore ?? DEFAULT_ORS_OPEN_BEFORE);
        setResourceDataLoading(false);
      }
    });
  }, [idResource, dispatch, setValue]);

  useEffect(() => {
    if (resource?.orchestrator?.id) dispatch(fetchProcessesByOrchestrator(resource.orchestrator.id)).then();
  }, [dispatch, resource]);

  const resourcePreAssignedProcesses = useSelector(
    ({ requests }) => requests.queries.FETCH_ROBOT_PROCESSES?.data,
  );

  const processList = useSelector(
    ({ requests }) => requests.queries.FETCH_PROCESSES_BY_ORCHESTRATOR?.data,
  );

  const currentUser = useSelector(
    ({ requests }) => requests.queries.FETCH_CURRENT_USER?.data,
  );

  useEffect(() => {
    if (resourcePreAssignedProcesses && processList) {
      setAssignedProcesses(resourcePreAssignedProcesses?.set || []);
      setAvailableProcesses(
        processList?.filter(
          (process) => !resourcePreAssignedProcesses.set?.find(
            (assigned) => assigned.id === process.id,
          ),
        ),
      );
    }
  }, [resourcePreAssignedProcesses, processList]);

  const handleSaveClick = async () => {
    const shouldValidateOrsFields = watch("isOrsEnabled");
    const result = await trigger(shouldValidateOrsFields ? ["orsLogin", "orsPassword", "orsResourceVmIp", "orsOpenBefore"] : [])
    if (result) {
      if (activeStep === enabledTabs.length - 1) {
        setOpenMsgConfirm(true);
      }
      if (activeStep < enabledTabs.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleCancel = () => {
    if (isEmpty(dirtyFields)) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };

  const redirectToList = () => history.push(RESOURCE_BASE_URL);

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  const confirmSave = () => {
    setIsLoading(true);
    submitUpdate();
    toast.success(t("resource.formControl.succesUpdate"))
  };

  const submitUpdate = () => {
      let orsDetail = null
      if (getValues("isOrsEnabled")) { orsDetail = {
              username: getValues("orsLogin"),
              password: getValues("orsPassword"),
              host: getValues("orsResourceVmIp"),
              height: getValues("orsScreenHeight") ?? DEFAULT_ORS_HEIGHT,
              width: getValues("orsScreenWidth") ?? DEFAULT_ORS_WIDTH,
              dpi: getValues("orsScreenDpi") ?? DEFAULT_ORS_DPI,
              openBefore: getValues("orsOpenBefore") ?? DEFAULT_ORS_OPEN_BEFORE
          } }
      const data = {
          processesId: assignedProcesses.map((el) => el.id),
          resourceName: getValues("resourceDisplayName"),
          orsDetail
      }
    dispatch(
      updateResource(idResource, data),
    ).then(() => {
      setIsLoading(false);
      setOpenMsgConfirm(false);
      redirectToList();
    });
  };

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    redirectToList();
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const handleChange = (_, newValue) => {
    setActiveStep(newValue);
  };

  const tabsContents = [
    {
      content: (
          resourceDataLoading ? (
            <CircularLoader height="100%" />
          ) : (
            <Grid container direction="column" justify="flex-start" spacing={7}>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("resourceDisplayName")}
                        fullWidth
                        valued={getValues("resourceDisplayName") && !disableFields}
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="resourceDisplayName"
                        label={t("resource.management.formControl.resourceName")}
                        InputLabelProps={{
                          shrink: !!getValues("resourceDisplayName"),
                        }}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("actionsRunning")}
                        fullWidth
                        id="actionsRunning"
                        name="actionsRunning"
                        label={`${t(
                            "resource.management.formControl.actionsRunning",
                        )} *`}
                        valued={getValues("actionsRunning") && !disableFields}
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: typeof getValues("actionsRunning") !== "undefined",
                        }}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("resourceName")}
                        fullWidth
                        valued={getValues("resourceName") && !disableFields}
                        InputProps={{
                          readOnly: true,
                        }}
                        id="resourceName"
                        label={t("resource.management.formControl.resourceOriginalName")}
                        InputLabelProps={{
                          shrink: !!getValues("resourceName"),
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("displayStatus")}
                        fullWidth
                        valued={getValues("displayStatus") && !disableFields}
                        InputProps={{
                          readOnly: true,
                        }}
                        id="displayStatus"
                        label={t("resource.management.displayStatus")}
                        InputLabelProps={{
                          shrink: !!getValues("displayStatus"),
                        }}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("resourceId")}
                        fullWidth
                        valued={getValues("resourceId") && !disableFields}
                        InputProps={{
                          readOnly: true,
                        }}
                        id="resourceId"
                        label={t("resource.management.formControl.resourceId")}
                        InputLabelProps={{
                          shrink: !!getValues("resourceId"),
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("processesRunning")}
                        fullWidth
                        valued={getValues("processesRunning") && !disableFields}
                        InputProps={{
                          readOnly: true,
                        }}
                        id="processesRunning"
                        label={`${t(
                            "resource.management.formControl.processesRunning",
                        )} *`}
                        InputLabelProps={{
                          shrink:
                              typeof getValues("processesRunning") !== "undefined",
                        }}
                    />
                </Grid>
              </Grid>
              <Grid item>
                <CustomSwitch
                  name="isOrsEnabled"
                  checked={watch("isOrsEnabled")}
                  handleChange={() => setValue(
                      "isOrsEnabled",
                      disableFields ? watch("isOrsEnabled") : !watch("isOrsEnabled"),
                  )}
                  label={t("resource.management.formControl.activateOrs")}
                />
              </Grid>
            </Grid>
          )
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("resource.button.cancel")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {t("next")}
          </CustomButton>
        </>
      ),
    },
    {
      content: (
        <Grid item xs={12}>
          <ProcessSelector
              availableProcesses={availableProcesses}
              selectedProcesses={assignedProcesses}
              isDisabled={mode === "view"}
              setAvailableProcesses={setAvailableProcesses}
              setSelectedProcesses={setAssignedProcesses}
            />
        </Grid>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("fleet.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {activeStep === enabledTabs.length - 1
                  ? (idResource && t("resource.button.update")) || t("user.button.save")
                  : t("next")}
          </CustomButton>
        </>
      ),
    },
    {
      content: resourceDataLoading ? (
        <CircularLoader height="100%" />
          ) : (
            <Grid container direction="column" spacing={7}>
              <Grid item>
                <CustomTextField
                {...register("orsLogin", {
                    required: t("user.management.formControl.required"),
                })}
                fullWidth
                valued={getValues("orsLogin") && !disableFields}
                InputProps={{
                  readOnly: disableFields,
                }}
                id="orsLogin"
                name="orsLogin"
                label={`${t("Login")} *`}
                error={!!errors.orsLogin}
                helperText={errors.orsLogin && errors.orsLogin.message}
            />
              </Grid>
              <Grid item>
                <CustomTextField
                {...register("orsPassword", {
                    required: t("user.management.formControl.required"),
                })}
                fullWidth
                id="orsPassword"
                name="orsPassword"
                type="password"
                label={`${t("orchestrator.management.formControl.dbPassword")} *`}
                valued={getValues("orsPassword") && !disableFields}
                InputProps={{
                  readOnly: disableFields,
                }}
                error={!!errors?.orsPassword}
                helperText={errors.orsPassword && errors.orsPassword.message}
            />
              </Grid>
              <Grid item>
                <CustomTextField
                {...register("orsResourceVmIp", {
                    required: t("user.management.formControl.required"),
                })}
                fullWidth
                id="orsResourceVmIp"
                name="orsResourceVmIp"
                label={`${t("resource.management.formControl.orsResourceVmIp")} *`}
                valued={getValues("orsResourceVmIp") && !disableFields}
                InputProps={{
                  readOnly: disableFields,
                }}
                error={!!errors.orsResourceVmIp}
                helperText={errors.orsResourceVmIp && errors.orsResourceVmIp.message}
            />
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={3}>
                  <CustomTextField
                  {...register("orsScreenHeight", {
                      required: t("user.management.formControl.required"),
                      min: {
                          value: 0,
                          message: t("restriction.form.minValue.error"),
                      },
                  })}
                  type="number"
                  fullWidth
                  valued={getValues("orsScreenHeight") && !disableFields}
                  InputProps={{
                    readOnly: disableFields,
                  }}
                  id="orsScreenHeight"
                  name="orsScreenHeight"
                  label={`${t("resource.management.formControl.orsScreenHeight")} *`}
                  error={!!errors.orsScreenHeight}
                  helperText={errors.orsScreenHeight && errors.orsScreenHeight.message}
              />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                  {...register("orsScreenWidth", {
                      required: t("user.management.formControl.required"),
                      min: {
                          value: 0,
                          message: t("restriction.form.minValue.error"),
                      },
                  })}
                  fullWidth
                  id="orsScreenWidth"
                  name="orsScreenWidth"
                  label={`${t("resource.management.formControl.orsScreenWidth")} *`}
                  valued={getValues("orsScreenWidth") && !disableFields}
                  InputProps={{
                    readOnly: disableFields,
                  }}
                  type="number"
                  error={!!errors.orsScreenWidth}
                  helperText={errors.orsScreenWidth && errors.orsScreenWidth.message}
              />
                </Grid>
                <Grid item xs={2}>
                  <CustomTextField
                      {...register("orsScreenDpi", {
                          required: t("user.management.formControl.required"),
                          min: {
                              value: 0,
                              message: t("restriction.form.minValue.error"),
                          },
                      })}
                      type="number"
                      fullWidth
                      valued={getValues("orsScreenDpi") && !disableFields}
                      InputProps={{
                          readOnly: disableFields,
                      }}
                      id="orsScreenDpi"
                      name="orsScreenDpi"
                      label={`${t("resource.management.formControl.orsScreenDpi")} *`}
                      error={!!errors.orsScreenDpi}
                      helperText={errors.orsScreenDpi && errors.orsScreenDpi.message}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    {...register("orsOpenBefore", {
                      required: t("user.management.formControl.required"),
                      min: {
                        value: 2,
                        message: t("restriction.form.minValue.error.param", { minVal: 2 }),
                      },
                      max: {
                        value: 127,
                        message: t("restriction.form.maxValue.error.param", { maxVal: 127 }),
                      },
                    })}
                    type="number"
                    fullWidth
                    valued={getValues("orsOpenBefore") && !disableFields}
                    InputProps={{
                      readOnly: disableFields,
                    }}
                    id="orsOpenBefore"
                    name="orsOpenBefore"
                    label={`${t("resource.management.formControl.orsOpenBefore")} *`}
                    error={!!errors.orsOpenBefore}
                    helperText={errors.orsOpenBefore && errors.orsOpenBefore.message}
                  />
                </Grid>
              </Grid>
            </Grid>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("fleet.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            { idResource ? t("resource.button.update") : t("user.button.save")}
          </CustomButton>
        </>
      ),
      disabledTab: !watch("isOrsEnabled"),
    },
  ];

  return (
    <>
      <DialogWithTabs
          open={props?.open}
          entityBaseUrl={RESOURCE_BASE_URL}
          moduleName="ressources"
          tabs={enabledTabs}
          disableFields={disableFields}
          editCondition={isPermitted(currentUser, "Edit Resources")}
          tabsContents={tabsContents.filter((item) => !item.disabledTab)}
          idItem={idResource}
          handleChange={handleChange}
          activeStep={activeStep}
          height={disableFields ? 433 : 510}
        />
      {openMsgConfirm && (
      <ConfirmMessage
            message={t("resource.update.confirmMsg")}
            openStart={openMsgConfirm}
            onCancel={cancelConfirm}
            onConfirm={confirmSave}
            buttonConfirm={t("resource.button.update")}
            buttonCancel={t("resource.button.cancel")}
            isLoading={isLoading}
          />
        )}
      {openMsgCancel && (
      <ConfirmMessage
            message={t("resource.update.discard")}
            openStart={openMsgCancel}
            onCancel={handleRejectCancelForm}
            onConfirm={handleAcceptCancelForm}
            buttonConfirm={t("resource.button.discard")}
            buttonCancel={t("resource.button.cancel")}
            isLoading={false}
          />
        )}
    </>
  );
}
